import React from "react"
import { graphql } from "gatsby"

// Utils
import { formatBlogPost } from "../utils/helpers/prismicHelpers"

// Components
import BlogPage from "../pageViews/blogPageView"

const BlogPageContainer = ({ data }: any) => {
  if (!data.blog || !data.blogPosts) {
    return null
  }

  const rawData = data.blog.data

  const formattedData = {
    metaTitle: rawData.metaTitle,
    metaDescription: rawData.metaDescription,
    pageTitle: rawData.page_title,
    introTitle: rawData.intro_title,
    introCopy: rawData.intro_copy,
  }

  // Format posts by year
  const formattedPosts = data.blogPosts.nodes.reduce(
    (acc: any, blogPost: any) => {
      // Find the post's publication year
      const pubDateParts = blogPost.data.publication_date.split("/")
      const pubYear = pubDateParts[pubDateParts.length - 1]

      // Inherit or create a new array to hold all posts from the pub year
      acc[pubYear] = acc[pubYear] || []

      // Format the post
      const formattedPostData = formatBlogPost(blogPost)

      // Push any new posts
      acc[pubYear].push(formattedPostData)

      return acc
    },
    {}
  )

  return <BlogPage data={formattedData} posts={formattedPosts} />
}

export const query = graphql`
  query GetAllBlogPosts {
    blog: prismicBlog {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        page_title
        intro_title
        intro_copy {
          html
        }
      }
    }
    blogPosts: allPrismicBlogPost(
      sort: { fields: data___publication_date, order: DESC }
    ) {
      nodes {
        slug: uid
        data {
          title {
            text
          }
          blogImg: meta_image {
            url
            alt
          }
          post_type
          publication_date(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`

export default BlogPageContainer
