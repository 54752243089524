import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { IPage, IBlogPost } from "../types/"

// Styling
import colors from "../styles/colors"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import { Row, Col } from "../components/layout/Grid"
import SEO from "../components/layout/SEO"
import Container from "../components/layout/Container"
import BlogPostList from "../components/BlogPostList"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface IProps {
  data: IPage
  posts: {
    [key: number]: IBlogPost[]
  }
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.white,
  },
}

const BlogPage = React.memo(({ data, posts }: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      <Container>
        {posts && (
          <BlogPostsWrapper>
            {Object.keys(posts)
              .reverse()
              .map((year: string) => {
                const yearAsNum = parseInt(year, 10)

                return (
                  <BlogPostYearlyCollection key={year}>
                    {posts[yearAsNum] && posts[yearAsNum].length && (
                      <Row>
                        <Col>
                          <BlogPostList
                            currentPage="blog"
                            posts={posts[yearAsNum]}
                          />
                        </Col>
                      </Row>
                    )}
                  </BlogPostYearlyCollection>
                )
              })}
          </BlogPostsWrapper>
        )}
      </Container>
    </Wrapper>
  )
})

// Main styles
const Wrapper = styled.div`
  color: ${colors.white};
`

const BlogPostsWrapper = styled.div`
  margin: 0;
  list-style: none;
`

const BlogPostYearlyCollection = styled.div`
  &:last-child {
    margin-bottom: var(--base-column-size);
  }
`

export default BlogPage
